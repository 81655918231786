<template>
  <mom-page
    title="User Registration e-Service"
    show-breadcrumb
    :breadcrumb="[
      {
        text: 'myMomPortal  ',
        href: '#',
      },
      {
        text: 'Work Passes and Permits',
        href: '#',
      },
    ]"
  >
    <mom-page-resp-header
      slot="header"
      user-name="User Name"
      allow-login
      :googleAnalyticsDetailsLogin="[
        {
          gEventName: 'LogIn',
          gtagId: `LoginButton`,
          gtagIsEvent: true,
          gtagIsException: false,
          gtagEventCategory: 'User Login',
          gtagEventLabel: 'User Login',
          gtagCustomIdentifier: 'User Login',
        },
      ]"
      :googleAnalyticsDetails="[
        {
          gEventName: 'Home',
          gtagId: `Home Loaded`,
          gtagIsEvent: true,
          gtagIsException: false,
          gtagEventCategory: 'Home Loaded',
          gtagEventLabel: 'Home Loaded',
          gtagCustomIdentifier: 'Home Loaded',
        },
      ]"
    />
    <mom-layout-wrapper class="MomLandingPage">
      <mom-card class="MomLandingPage__Card">
        <h2 class="mom-h2 MomLandingPage__Title">E-Registration Form</h2>
        <div class="MomLandingPage__Content">
          <mom-grid-container>
            <mom-grid-column size="6">
              <mom-card title="Card title 1" variant="requirement">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </mom-card>
            </mom-grid-column>
            <mom-grid-column size="6">
              <mom-card title="Card title 2" variant="requirement">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </mom-card>
            </mom-grid-column>
          </mom-grid-container>
        </div>

        <div class="MomLandingPage__Note">
          <h3 class="mom-h3">Things to note</h3>
          <ul class="mom-ul">
            <li>Note 1</li>
            <li>Note 2</li>
          </ul>
        </div>
        <br /><br />
        <p class="MomLandingPage__Duration">
          <mom-icon icon="time" />
          <span> Takes about 30 mins to complete</span>
        </p>
        <mom-button
          icon="arrow-right"
          text="Start"
          @click="onStart"
          :googleAnalyticsDetails="[
            {
              gEventName: 'Start',
              gtagId: `StartButton`,
              gtagIsEvent: true,
              gtagIsException: false,
              gtagEventCategory: 'Start',
              gtagEventLabel: 'Application Start',
              gtagCustomIdentifier: 'Application Start',
            },
          ]"
        />
        <mom-button
          class="MomLandingPage__Draft"
          text="Retrieve From Draft"
          @click="onDraftRetrieve"
          :googleAnalyticsDetails="[
            {
              gEventName: 'RetrieveDraft',
              gtagId: `RetrieveButton`,
              gtagIsEvent: true,
              gtagIsException: false,
              gtagEventCategory: 'Retrieve Draft',
              gtagEventLabel: 'Application Retrieve',
              gtagCustomIdentifier: 'Application Retrieve',
            },
            {
              gEventName: 'AcceleratorSkipButton',
              gtagId: `RetrieveDraftButton`,
              gtagIsEvent: true,
              gtagIsException: false,
              gtagEventCategory: 'Retrieve Draft',
              gtagEventLabel: 'Application Retrieve',
              gtagCustomIdentifier: 'Application Retrieve',
            },
          ]"
        />
      </mom-card>
    </mom-layout-wrapper>
    <mom-page-footer slot="footer" />
  </mom-page>
</template>

<script>
import {
  MomPageRespHeader,
  MomPageFooter,
} from "../../dxplus_release/components/page.esm";
import { MomPage } from "../../dxplus_release/template.esm";
import { MomCard } from "../../dxplus_release/components/mom_card.esm";
import {
  MomGridContainer,
  MomGridColumn,
} from "../../dxplus_release/components/gridsystem.esm";
import { MomLayoutWrapper } from "../../dxplus_release/components/layout.esm";
import { MomButton } from "../../dxplus_release/components/button.esm";
import { MomIcon } from "../../dxplus_release/components/icon.esm";

export default {
  name: "DxERegistrationForm",
  components: {
    MomPage,
    MomPageRespHeader,
    MomPageFooter,
    MomLayoutWrapper,
    MomCard,
    MomGridContainer,
    MomGridColumn,
    MomButton,
    MomIcon,
  },
  methods: {
    onDraftRetrieve() {
      alert("Your saved details have been retrieved. Please check all fields.");
      this.$router.push({ path: "DxEService" });
    },
    onStart() {
      alert("Please fill all mandatory fields.");
      this.$router.push({ path: "DxEService" });
    },
  },
};
</script>

<style lang="scss" scoped>
$color-cool-grey-00: #f9fafb;
$color-grey-20: #e2e2e2;
$color-grey-10: #ededed;
$color-white: #ffffff;

.MomLandingPage__Card {
  padding-bottom: 3rem;
  margin-top: 2rem;
}

.MomLandingPage__Title {
  margin-bottom: 2rem;
}

.MomLandingPage__Content {
  margin-bottom: 2rem;
}

.MomLandingPage__Note {
  background-color: $color-cool-grey-00;
  margin-left: -2rem;
  margin-right: -2rem;
  margin-bottom: -2rem;
  padding: 2rem;
}

.MomLandingPage__Duration {
  margin-bottom: 2rem;
}

.MomLandingPage__Draft {
  margin-left: 1rem;
}
</style>
