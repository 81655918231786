var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mom-page',{attrs:{"title":"User Registration e-Service","show-breadcrumb":"","breadcrumb":[
    {
      text: 'myMomPortal  ',
      href: '#',
    },
    {
      text: 'Work Passes and Permits',
      href: '#',
    } ]}},[_c('mom-page-resp-header',{attrs:{"slot":"header","user-name":"User Name","allow-login":"","googleAnalyticsDetailsLogin":[
      {
        gEventName: 'LogIn',
        gtagId: "LoginButton",
        gtagIsEvent: true,
        gtagIsException: false,
        gtagEventCategory: 'User Login',
        gtagEventLabel: 'User Login',
        gtagCustomIdentifier: 'User Login',
      } ],"googleAnalyticsDetails":[
      {
        gEventName: 'Home',
        gtagId: "Home Loaded",
        gtagIsEvent: true,
        gtagIsException: false,
        gtagEventCategory: 'Home Loaded',
        gtagEventLabel: 'Home Loaded',
        gtagCustomIdentifier: 'Home Loaded',
      } ]},slot:"header"}),_c('mom-layout-wrapper',{staticClass:"MomLandingPage"},[_c('mom-card',{staticClass:"MomLandingPage__Card"},[_c('h2',{staticClass:"mom-h2 MomLandingPage__Title"},[_vm._v("E-Registration Form")]),_c('div',{staticClass:"MomLandingPage__Content"},[_c('mom-grid-container',[_c('mom-grid-column',{attrs:{"size":"6"}},[_c('mom-card',{attrs:{"title":"Card title 1","variant":"requirement"}},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ")])],1),_c('mom-grid-column',{attrs:{"size":"6"}},[_c('mom-card',{attrs:{"title":"Card title 2","variant":"requirement"}},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ")])],1)],1)],1),_c('div',{staticClass:"MomLandingPage__Note"},[_c('h3',{staticClass:"mom-h3"},[_vm._v("Things to note")]),_c('ul',{staticClass:"mom-ul"},[_c('li',[_vm._v("Note 1")]),_c('li',[_vm._v("Note 2")])])]),_c('br'),_c('br'),_c('p',{staticClass:"MomLandingPage__Duration"},[_c('mom-icon',{attrs:{"icon":"time"}}),_c('span',[_vm._v(" Takes about 30 mins to complete")])],1),_c('mom-button',{attrs:{"icon":"arrow-right","text":"Start","googleAnalyticsDetails":[
          {
            gEventName: 'Start',
            gtagId: "StartButton",
            gtagIsEvent: true,
            gtagIsException: false,
            gtagEventCategory: 'Start',
            gtagEventLabel: 'Application Start',
            gtagCustomIdentifier: 'Application Start',
          } ]},on:{"click":_vm.onStart}}),_c('mom-button',{staticClass:"MomLandingPage__Draft",attrs:{"text":"Retrieve From Draft","googleAnalyticsDetails":[
          {
            gEventName: 'RetrieveDraft',
            gtagId: "RetrieveButton",
            gtagIsEvent: true,
            gtagIsException: false,
            gtagEventCategory: 'Retrieve Draft',
            gtagEventLabel: 'Application Retrieve',
            gtagCustomIdentifier: 'Application Retrieve',
          },
          {
            gEventName: 'AcceleratorSkipButton',
            gtagId: "RetrieveDraftButton",
            gtagIsEvent: true,
            gtagIsException: false,
            gtagEventCategory: 'Retrieve Draft',
            gtagEventLabel: 'Application Retrieve',
            gtagCustomIdentifier: 'Application Retrieve',
          } ]},on:{"click":_vm.onDraftRetrieve}})],1)],1),_c('mom-page-footer',{attrs:{"slot":"footer"},slot:"footer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }